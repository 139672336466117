<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
            @onPageChange="onPageChange" :queryParam="queryParam">
            <p slot="elList">
                <el-table ref="apiListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange"
                    highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col, index, count) in dataSource.ColDefs.BodyFieldParams" :key="index"
                        :prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam, col)"
                        :fixed="index == 0 && (!config || !config.isDetailDisplay)"
                        :min-width="(!config || !config.isDetailDisplay) ? parseInt(col.Width) : 0"
                        v-if="col.Visible">
                        <template slot-scope="scope">
                            <span> {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text" @click="downloadFile(scope.row)">下载文件</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
    </div>
</template>
<script>
import sha from '../../../../../scripts/sha.js';
export default {
    mounted() {
        this.reloadPageList();
    },
    data() {
        return {
            multipleSelection: [],
            dataSource: {
                ColDefs: {
                    BodyFieldParams: []
                },
                Result: [],
                TotalCount: 0
            },
            queryParam: {
                PageIndex: 1,
                PageSize: 10
            },
        }
    },
    props: {
        config: {
            isDetailDisplay: false,
        }
    },
    methods: {
        reloadPageList() {
            this.onPageChange(this.queryParam);
        },
        onDataSourceChange(ds) {
            var _this = this;
            _this.dataSource = {
                ColDefs: {
                    BodyFieldParams: []
                },
                Result: [],
                TotalCount: 0
            };
            _this.$nextTick(function () {
                _this.dataSource = ds;
            });
        },
        onPageChange(param) {
            this.queryParam = param;
            this.$ajax.send("omsapi/exportfileinfo/getpagelist", "post", param, data => {
                this.dataSource = data;
            });
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        onChangeEditDataSource(data) {
            this.config.isDetailDisplay = true;
            this.$emit("onChangeEditDataSource", data);
        },
        onChangeDetailDataSource(data) {
            this.config.isDetailDisplay = true;
            this.$emit("onChangeDetailDataSource", data);
        },
        add() {
            this.onChangeEditDataSource(null);
        },
        del() {
            if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                this.Utils.messageBox(`未选数据.`, "error");
                return false;
            }
            var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");
            var _this = this
            this.$confirm('确定要删除吗？').then(_ => {
                _this.$ajax.send("omsapi/exportfileinfo/delete", "post", ids, data => {
                    if (data.IsSuccess) {
                        _this.Utils.messageBox("删除成功.", "success");
                        _this.reloadPageList();
                    } else {
                        _this.Utils.messageBox(data.OperationDesc, "error");
                    }
                });
            }).catch(_ => { });
        },
        rowClick: function (row) {
            this.onChangeDetailDataSource(row.Id);
        },
        editClick(row) {
            this.onChangeEditDataSource(row.Id);
        },
        downloadFile(row){
            this.Utils.exportUrl("omsapi/files/download?file="+row.FileName+"&url="+row.FileUrl + "&token=" + sha.getToken1());
        }
    },
}
</script>